<template>
  <div style="min-height: 100vh;" class="min-h-screen py-12 bg-hp-gray">

    <div class="max-w-5xl px-4 mx-auto">
      <div class="flex justify-between items-center mb-8">
        <router-link to="/">
          <img src="@/assets/logos/Homeplan logo (new modern April 2022 - NO .com).svg" style="height: 2.1rem;" alt="Homeplan logo">
        </router-link>
        
        <div class="flex justify-center gap-4">
          <div v-for="index in [1,2,3,4,5,6,7]" :key="index">
            <div v-if="index <= state.step" class="bg-blue-900 rounded-full h-5 w-5 flex items-center justify-center text-white font-bold border-2 border-blue-900 text-xs text-bold" />
            <div v-else class="bg-gray-200 rounded-full h-5 w-5 flex items-center justify-center text-white font-bold border-2 border-gray-200 text-xs text-bold" />
          </div>
        </div>
      </div>

      <div class="vertical-center pb-16">

        <!-- Step 1 -->
        <form @submit.prevent="completeStepOne" class="w-full" v-if="state.step == 1">
          <div class="text-center font-bold mb-2">STEP 1</div>
          <div class="text-center text-4xl font-bold mb-24">Tell us about <span class="text-blue-900">your business</span></div>

          <div class="grid grid-cols-2 gap-6 mb-20">
            <div class="cursor-text relative">
              <input required v-model="form.details.name" id="business-name" placeholder="Placeholder" type="text" class="floating-input">
              <label for="business-name">Business name</label>
            </div>

            <div class="cursor-text relative">
              <input required v-model="form.details.yourName" id="your-name" placeholder="Placeholder" type="text" class="floating-input">
              <label for="your-name">Your name</label>
            </div>

            <div class="cursor-text relative">
              <input required v-model="form.details.yourRole" id="your-role" placeholder="Placeholder" type="text" class="floating-input">
              <label for="your-role">Your role</label>
            </div>

            <div class="cursor-text relative">
              <input required v-model="form.details.email" id="your-email" placeholder="Placeholder" type="email" class="floating-input">
              <label for="your-email">Your email address</label>
            </div>

            <div class="cursor-text relative">
              <input required v-model="form.details.mobilePhone" id="phone-no" placeholder="Placeholder" type="tel" class="floating-input">
              <label for="phone-no">Phone number</label>
            </div>

            <div class="cursor-text relative">
              <input required v-model="form.details.website" id="website" placeholder="Placeholder" type="text" class="floating-input">
              <label for="website">Company website</label>
            </div>

            <div class="col-span-2">
              <label class="text-sm font-medium text-gray-700">Upload your company logo</label>
              <file-pond
                class-name="my-pond" name="logo" ref="logo-pond"
                label-idle="Drag and drop your logo or <span class='filepond--label-action'>Browse</span>."
                :allow-multiple="false" accepted-file-types="image/jpeg, image/png"
                :files="logos" :allowRevert="false" labelTapToUndo=""
                allow-file-type-validation="true"
                :allowImagePreview="true"
                :allowImageCrop="true"
              />
            </div>
          </div>

          <div class="flex justify-center gap-2">
            <router-link to="/" class="bg-gray-100 hover:bg-gray-200 duration-100 ease-linear rounded shadow-md text-xl py-3 px-4 text-gray-400">
              Exit form
            </router-link>

            <button type="submit" class="text-2xl py-3 px-8 bg-blue-900 hover:bg-blue-700 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none rounded-lg">
              Next
            </button>
          </div>
        </form>


        <!-- Step 2 -->
        <form @submit.prevent="state.step++" class="w-full" v-if="state.step == 2">
          <div class="text-center font-bold mb-2">STEP 2</div>
          <div class="text-center text-4xl font-bold mb-24">What is your <span class="text-blue-900">availability?</span></div>

          <div class="mb-20 max-w-3xl w-full mx-auto">
            <div class="font-medium text-2xl text-center mb-4">What states does your business operate in?</div>
          
            <div class="relative">
              <input required id="states-input" placeholder=" " v-model="form.availability.states" type="text" class="floating-input-lg">
              <label for="states-input">List states (separate with commas)...</label>
            </div>
          </div>

          <div class="mb-20 max-w-3xl w-full mx-auto">
            <div class="font-medium text-2xl text-center mb-4">What ZIP codes does {{ form.details.name }} service?</div>
          
            <div class="relative">
              <input required id="zips-input" placeholder=" " v-model="form.availability.areas" type="text" class="floating-input-lg">
              <label for="zips-input">List ZIP codes (separate with commas)...</label>
            </div>
          </div>

          <div class="mb-20 max-w-3xl w-full mx-auto">
            <div class="font-medium text-2xl text-center mb-4">When are you available?</div>
          
            <div class="flex justify-center gap-4">
              <div @click="form.availability.timesAvailable = toggleArrayElement(form.availability.timesAvailable, 'Weekdays')" :class="{ 'bg-blue-900 hover:bg-blue-800 text-white': form.availability.timesAvailable.includes('Weekdays'), 'bg-gray-200 hover:bg-gray-300': !form.availability.timesAvailable.includes('Weekdays') }" class="shadow-md flex gap-2 items-center cursor-pointer transition duration-150 ease-in-out font-medium rounded px-4 py-3 text-lg">
                <img v-if="form.availability.timesAvailable.includes('Weekdays')" class="h-6" src="https://img.icons8.com/ios-glyphs/30/ffffff/checked-checkbox.png"/>
                <img v-else class="h-6" src="https://img.icons8.com/ios-filled/50/000000/unchecked-checkbox.png"/>
                Weekdays
              </div>

              <div @click="form.availability.timesAvailable = toggleArrayElement(form.availability.timesAvailable, 'Weekends')" :class="{ 'bg-blue-900 hover:bg-blue-800 text-white': form.availability.timesAvailable.includes('Weekends'), 'bg-gray-200 hover:bg-gray-300': !form.availability.timesAvailable.includes('Weekends') }" class="shadow-md flex gap-2 items-center cursor-pointer transition duration-150 ease-in-out font-medium rounded px-4 py-3 text-lg">
                <img v-if="form.availability.timesAvailable.includes('Weekends')" class="h-6" src="https://img.icons8.com/ios-glyphs/30/ffffff/checked-checkbox.png"/>
                <img v-else class="h-6" src="https://img.icons8.com/ios-filled/50/000000/unchecked-checkbox.png"/>
                Weekends
              </div>

              <div @click="form.availability.timesAvailable = toggleArrayElement(form.availability.timesAvailable, 'Nights')" :class="{ 'bg-blue-900 hover:bg-blue-800 text-white': form.availability.timesAvailable.includes('Nights'), 'bg-gray-200 hover:bg-gray-300': !form.availability.timesAvailable.includes('Nights') }" class="shadow-md flex gap-2 items-center cursor-pointer transition duration-150 ease-in-out font-medium rounded px-4 py-3 text-lg">
                <img v-if="form.availability.timesAvailable.includes('Nights')" class="h-6" src="https://img.icons8.com/ios-glyphs/30/ffffff/checked-checkbox.png"/>
                <img v-else class="h-6" src="https://img.icons8.com/ios-filled/50/000000/unchecked-checkbox.png"/>
                Nights
              </div>
            </div>
          </div>

          <div class="max-w-3xl w-full mx-auto mb-24">
            <div class="font-medium text-2xl text-center mb-4">How many employees does {{ form.details.name }} have?</div>
          
            <div class="flex flex-wrap justify-center gap-4">
              <div @click="form.availability.employees = option" v-for="option in ['None', '1', '2 - 5', '5 - 10', '11 - 25', '25+']" :key="option" :class="{ 'bg-blue-900 hover:bg-blue-800 text-white': form.availability.employees == option, 'bg-gray-200 hover:bg-gray-300': !(form.availability.employees == option) }" class="shadow-md flex gap-2 items-center cursor-pointer transition duration-150 ease-in-out font-medium rounded px-4 py-3 text-lg">
                {{ option }}
              </div>
            </div>
          </div>

          <div class="flex justify-center gap-2">
            <button type="button" @click="state.step--" class="text-2xl py-3 px-8 bg-black hover:bg-gray-900 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none rounded-lg">
              Back
            </button>

            <button type="submit" class="text-2xl py-3 px-8 bg-blue-900 hover:bg-blue-700 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none rounded-lg">
              Next
            </button>
          </div>
        </form>


        <!-- Step 3 -->
        <div class="w-full" v-if="state.step == 3">
          <div class="text-center font-bold mb-2">STEP 3</div>
          <div class="text-center text-4xl font-bold mb-24">Business License & Insurance</div>

          <div class="max-w-3xl w-full mx-auto mb-20">
            <div class="font-medium text-2xl text-center mb-4">Do your business have and/or need a license to operate?</div>
          
            <div class="flex flex-wrap justify-center gap-4">
              <div @click="form.insurance.hasLicense = true" :class="{ 'bg-blue-900 hover:bg-blue-800 text-white': form.insurance.hasLicense, 'bg-gray-200 hover:bg-gray-300': !(form.insurance.hasLicense) }" class="shadow-md flex gap-2 items-center cursor-pointer transition duration-150 ease-in-out font-medium rounded px-4 py-3 text-lg">
                Yes
              </div>

              <div @click="form.insurance.hasLicense = false" :class="{ 'bg-blue-900 hover:bg-blue-800 text-white': !form.insurance.hasLicense, 'bg-gray-200 hover:bg-gray-300': (form.insurance.hasLicense) }" class="shadow-md flex gap-2 items-center cursor-pointer transition duration-150 ease-in-out font-medium rounded px-4 py-3 text-lg">
                No
              </div>
            </div>
          </div>

          <div class="max-w-3xl w-full mx-auto mb-20">
            <div class="font-medium text-2xl text-center mb-4">Do you have General Liability insurance?</div>
          
            <div class="flex flex-wrap justify-center gap-4">
              <div @click="form.insurance.hasGeneralLiability = true" :class="{ 'bg-blue-900 hover:bg-blue-800 text-white': form.insurance.hasGeneralLiability, 'bg-gray-200 hover:bg-gray-300': !(form.insurance.hasGeneralLiability) }" class="shadow-md flex gap-2 items-center cursor-pointer transition duration-150 ease-in-out font-medium rounded px-4 py-3 text-lg">
                Yes
              </div>

              <div @click="form.insurance.hasGeneralLiability = false" :class="{ 'bg-blue-900 hover:bg-blue-800 text-white': !form.insurance.hasGeneralLiability, 'bg-gray-200 hover:bg-gray-300': (form.insurance.hasGeneralLiability) }" class="shadow-md flex gap-2 items-center cursor-pointer transition duration-150 ease-in-out font-medium rounded px-4 py-3 text-lg">
                No
              </div>
            </div>
          </div>

          <div class="max-w-3xl w-full mx-auto mb-28">
            <div class="font-medium text-2xl text-center mb-4">Do you have Workers Comp insurance?</div>
          
            <div class="flex flex-wrap justify-center gap-4">
              <div @click="form.insurance.hasWorkersComp = true" :class="{ 'bg-blue-900 hover:bg-blue-800 text-white': form.insurance.hasWorkersComp, 'bg-gray-200 hover:bg-gray-300': !(form.insurance.hasWorkersComp) }" class="shadow-md flex gap-2 items-center cursor-pointer transition duration-150 ease-in-out font-medium rounded px-4 py-3 text-lg">
                Yes
              </div>

              <div @click="form.insurance.hasWorkersComp = false" :class="{ 'bg-blue-900 hover:bg-blue-800 text-white': !form.insurance.hasWorkersComp, 'bg-gray-200 hover:bg-gray-300': (form.insurance.hasWorkersComp) }" class="shadow-md flex gap-2 items-center cursor-pointer transition duration-150 ease-in-out font-medium rounded px-4 py-3 text-lg">
                No
              </div>
            </div>
          </div>

          <div class="flex justify-center gap-2">
            <button @click="state.step--" class="text-2xl py-3 px-8 bg-black hover:bg-gray-900 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none rounded-lg">
              Back
            </button>

            <button @click="state.step++" class="text-2xl py-3 px-8 bg-blue-900 hover:bg-blue-700 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none rounded-lg">
              Next
            </button>
          </div>
        </div>


        <!-- Step 4 -->
        <div class="w-full" v-if="state.step == 4">
          <div class="text-center font-bold mb-2">STEP 4</div>
          <div class="text-center text-4xl font-bold mb-24">Upload License & Insurance</div>

          <div v-if="(!form.insurance.hasGeneralLiability && !form.insurance.hasWorkersComp && !form.insurance.hasLicense)">
            <div class="text-xl text-center mb-6">
              In order to process your request we require servicers to have and maintain General Liability Insurance and Workers Comp insurance.
            </div>

            <div class="text-xl text-center mb-24">
              You may continue to proceed with your request. Please note a Homeplan representative will ask for you to provide this information.
            </div>
          </div>

          <div class="max-w-3xl w-full mx-auto mb-20" v-if="form.insurance.hasLicense">
            <div class="font-medium text-2xl text-center mb-4">Upload your license</div>
          
            <file-pond
              class-name="my-pond" name="logo" ref="license-pond"
              label-idle="Drag and drop your document or <span class='filepond--label-action'>Browse</span>."
              :allow-multiple="false" accepted-file-types="application/pdf, text/plain, image/png, image/jpeg, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              :files="logos" :allowRevert="false" labelTapToUndo=""
              allow-file-type-validation="true"
              :allowImagePreview="true"
              :allowImageCrop="true"
            />
          </div>

          <div class="max-w-3xl w-full mx-auto mb-20" v-if="form.insurance.hasGeneralLiability">
            <div class="font-medium text-2xl text-center mb-4">Upload your General Liability insurance</div>
          
            <file-pond
              class-name="my-pond" name="logo" ref="general-liability-pond"
              label-idle="Drag and drop your document or <span class='filepond--label-action'>Browse</span>."
              :allow-multiple="false" accepted-file-types="application/pdf, text/plain, image/png, image/jpeg, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              :files="logos" :allowRevert="false" labelTapToUndo=""
              allow-file-type-validation="true"
              :allowImagePreview="true"
              :allowImageCrop="true"
            />
          </div>

          <div class="max-w-3xl w-full mx-auto mb-28" v-if="form.insurance.hasWorkersComp">
            <div class="font-medium text-2xl text-center mb-4">Upload your Workers Comp insurance</div>
          
            <file-pond
              class-name="my-pond" name="logo" ref="workers-pond"
              label-idle="Drag and drop your document or <span class='filepond--label-action'>Browse</span>."
              :allow-multiple="false" accepted-file-types="application/pdf, text/plain, image/png, image/jpeg, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              :files="logos" :allowRevert="false" labelTapToUndo=""
              allow-file-type-validation="true"
              :allowImagePreview="true"
              :allowImageCrop="true"
            />
          </div>

          <div class="flex justify-center gap-2">
            <button @click="state.step--" class="text-2xl py-3 px-8 bg-black hover:bg-gray-900 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none rounded-lg">
              Back
            </button>

            <button @click="completeStepFour" class="text-2xl py-3 px-8 bg-blue-900 hover:bg-blue-700 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none rounded-lg">
              Next
            </button>
          </div>
        </div>


        <!-- Step 5 -->
        <div class="w-full" v-if="state.step == 5">
          <div class="text-center font-bold mb-2">STEP 5</div>
          <div class="text-center text-4xl font-bold mb-24">BBB and Online Reviews</div>

          <!-- BBB -->
          <div class="max-w-3xl w-full mx-auto mb-20">
            <div class="font-medium text-2xl text-center mb-4">Does your business have a Better Business Bureau (BBB) account?</div>
          
            <div class="flex flex-wrap justify-center gap-4">
              <div @click="form.reviews.bbb = true" :class="{ 'bg-blue-900 hover:bg-blue-800 text-white': form.reviews.bbb, 'bg-gray-200 hover:bg-gray-300': !(form.reviews.bbb) }" class="shadow-md flex gap-2 items-center cursor-pointer transition duration-150 ease-in-out font-medium rounded px-4 py-3 text-lg">
                Yes
              </div>

              <div @click="form.reviews.bbb = false" :class="{ 'bg-blue-900 hover:bg-blue-800 text-white': !form.reviews.bbb, 'bg-gray-200 hover:bg-gray-300': (form.reviews.bbb) }" class="shadow-md flex gap-2 items-center cursor-pointer transition duration-150 ease-in-out font-medium rounded px-4 py-3 text-lg">
                No
              </div>
            </div>
          </div>

          <div class="mb-20 max-w-3xl w-full mx-auto" v-if="form.reviews.bbb">
            <div class="font-medium text-2xl text-center mb-4">Please enter your Better Business Bureau (BBB) page URL</div>
          
            <div class="relative">
              <input required id="bbb-input" placeholder=" " v-model="form.reviews.bbbLink" type="address" class="floating-input-lg">
              <label for="bbb-input">Enter Better Business Bureau (BBB) page URL...</label>
            </div>
          </div>


          <!-- Google -->
          <div class="max-w-3xl w-full mx-auto mb-20">
            <div class="font-medium text-2xl text-center mb-4">Does your business have Google reviews?</div>
          
            <div class="flex flex-wrap justify-center gap-4">
              <div @click="form.reviews.google = true" :class="{ 'bg-blue-900 hover:bg-blue-800 text-white': form.reviews.google, 'bg-gray-200 hover:bg-gray-300': !(form.reviews.google) }" class="shadow-md flex gap-2 items-center cursor-pointer transition duration-150 ease-in-out font-medium rounded px-4 py-3 text-lg">
                Yes
              </div>

              <div @click="form.reviews.google = false" :class="{ 'bg-blue-900 hover:bg-blue-800 text-white': !form.reviews.google, 'bg-gray-200 hover:bg-gray-300': (form.reviews.google) }" class="shadow-md flex gap-2 items-center cursor-pointer transition duration-150 ease-in-out font-medium rounded px-4 py-3 text-lg">
                No
              </div>
            </div>
          </div>

          <div class="mb-20 max-w-3xl w-full mx-auto" v-if="form.reviews.google">
            <div class="font-medium text-2xl text-center mb-4">Please enter your Google reviews page URL</div>
          
            <div class="relative">
              <input required id="google-input" placeholder=" " v-model="form.reviews.googleLink" type="address" class="floating-input-lg">
              <label for="google-input">Enter Google reviews page URL...</label>
            </div>
          </div>


          <!-- Yelp -->
          <div class="max-w-3xl w-full mx-auto mb-20">
            <div class="font-medium text-2xl text-center mb-4">Does your business have Yelp reviews?</div>
          
            <div class="flex flex-wrap justify-center gap-4">
              <div @click="form.reviews.yelp = true" :class="{ 'bg-blue-900 hover:bg-blue-800 text-white': form.reviews.yelp, 'bg-gray-200 hover:bg-gray-300': !(form.reviews.yelp) }" class="shadow-md flex gap-2 items-center cursor-pointer transition duration-150 ease-in-out font-medium rounded px-4 py-3 text-lg">
                Yes
              </div>

              <div @click="form.reviews.yelp = false" :class="{ 'bg-blue-900 hover:bg-blue-800 text-white': !form.reviews.yelp, 'bg-gray-200 hover:bg-gray-300': (form.reviews.yelp) }" class="shadow-md flex gap-2 items-center cursor-pointer transition duration-150 ease-in-out font-medium rounded px-4 py-3 text-lg">
                No
              </div>
            </div>
          </div>

          <div class="mb-20 max-w-3xl w-full mx-auto" v-if="form.reviews.yelp">
            <div class="font-medium text-2xl text-center mb-4">Please enter your Yelp reviews page URL</div>
          
            <div class="relative">
              <input required id="yelp-input" placeholder=" " v-model="form.reviews.yelpLink" type="address" class="floating-input-lg">
              <label for="yelp-input">Enter Yelp reviews page URL...</label>
            </div>
          </div>

          <div class="flex justify-center gap-2">
            <button @click="state.step--" class="text-2xl py-3 px-8 bg-black hover:bg-gray-900 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none rounded-lg">
              Back
            </button>

            <button @click="state.step++" class="text-2xl py-3 px-8 bg-blue-900 hover:bg-blue-700 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none rounded-lg">
              Next
            </button>
          </div>
        </div>


        <!-- Step 6 -->
        <div class="w-full" v-if="state.step == 6">
          <div class="text-center font-bold mb-2">STEP 6</div>
          <div class="text-center text-4xl font-bold mb-20">What <span class="text-blue-900">services</span> do you offer?</div>

          <div class="font-medium text-center text-2xl mb-4">Select the categories that your business provides services in.</div>
        
          <div class="grid grid-cols-4 gap-4 mb-20">
            <div @click="toggleCategory(category)" v-for="category in serviceCategories" :key="category" class="cursor-pointer hover:bg-gray-100 hover:text-gray-800 transition duration-200 ease-in-out flex items-center text-xl font-semibold justify-center border-2 border-gray-200 shadow-sm p-4 rounded-lg h-24" :class="{ 'text-hp-white bg-blue-900 hover:bg-blue-700 hover:text-hp-white': form.categories.includes(category), 'bg-hp-white': !form.categories.includes(category) }">
              {{ category }}
            </div>
          </div>

          <div class="flex justify-center gap-2">
            <button @click="state.step--" class="text-2xl py-3 px-8 bg-black hover:bg-gray-900 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none rounded-lg">
              Back
            </button>

            <button @click="completeStepSix" class="text-2xl py-3 px-8 bg-blue-900 hover:bg-blue-700 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none rounded-lg">
              Next
            </button>
          </div>
        </div>


        <!-- Step 7 -->
        <div class="w-full" v-if="state.step == 7">
          <div class="text-center font-bold mb-2">STEP 7</div>
          <div class="text-center text-4xl font-bold mb-4">Servicer agreement</div>

          <!-- Loading contract -->
          <div class="py-56 flex flex-col gap-8 items-center justify-center" v-if="state.isLoading">
            <img class="animate-spin h-10 w-10" src="https://img.icons8.com/ios/50/000000/iphone-spinner--v1.png"/>
            <div class="font-medium text-center text-xl text-center">Loading servicer agreement</div>
          </div>

          <!-- iFrame for contract -->
          <div v-else>
            <div class="font-medium text-center text-2xl mb-4">
              Please carefully read through and sign our servicer agreement. A six-digit code has been sent to your email. Please enter this code at the end of the document.
            </div>

            <iframe
              class="my-12 border-1 border-gray-300 shadow-md"
              :src="contractLink"
              id="eSignaturesIOIframe"
              onload="iFrameResize({heightCalculationMethod: 'bodyScroll'}, '#eSignaturesIOIframe')"
              style="width: 1px;min-width: 100%; height: 50vh;">
            </iframe>
          
            <div class="flex justify-center gap-2">
              <button @click="state.step--" class="text-2xl py-3 px-8 bg-black hover:bg-gray-900 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none rounded-lg">
                Back
              </button>

              <button @click="state.step++" class="text-2xl py-3 px-8 bg-blue-900 hover:bg-blue-700 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none rounded-lg">
                Continue
              </button>
            </div>
          </div>
        </div>


        <!-- Step 8 -->
        <div class="w-full flex flex-col justify-center" v-if="state.step == 8">
          <div class="text-center text-4xl font-bold mb-8">Onboarding complete!</div>
          <div class="text-xl text-center mb-12">
            Thank you for your interest in joining Homeplan. We'll email you with the next steps after reviewing your information.
          </div>
          <router-link to="/" class="text-2xl py-3 px-8 bg-blue-900 hover:bg-gray-900 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none rounded-lg">
            Return to home
          </router-link>
        </div>

      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import { serviceCategories }  from "@/js/constants.js";
import { v4 as uuidv4 } from 'uuid';
import Footer from "@/components/footer.vue";

// Filepond for uploading logo
import vueFilePond from 'vue-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';

import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';

const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview, FilePondPluginImageCrop);

const axios = require('axios');

export default {
  components: {
    FilePond,
    Footer
  },
  data() {
    return {
      state: {
        step: 1,
        isLoading: false
      },

      contractLink: '',

      serviceCategories,

      logos: [],

      files: {},

      form: {
        id: '',
        
        details: {
          name: '',
          yourName: '',
          yourRole: '',
          mobilePhone: '',
          email: '',
          website: ''
        },

        availability: {
          areas: '',
          states: '',
          timesAvailable: [],
          employees: 'None'
        },

        insurance: {
          hasLicense: true,
          hasGeneralLiability: true,
          hasWorkersComp: true
        },

        reviews: {
          bbb: false,
          bbbLink: '',

          google: false,
          googleLink: '',

          yelp: false,
          yelpLink: ''
        },

        categories: []
      }
    }
  },
  created() {
    this.form.id = uuidv4();
  },
  methods: {
    completeStepOne() {
      const logoFilePond = this.$refs['logo-pond'].getFiles();
      if (logoFilePond.length > 0) {
        const file = logoFilePond[0].file;
        this.files['Logo'] = file;
      }

      this.state.step++;
    },
    completeStepFour() {
      if (this.form.insurance.hasLicense) {
        const licenseFilePond = this.$refs['license-pond'].getFiles();

        if (licenseFilePond.length > 0) {
          const file = licenseFilePond[0].file;
          this.files['License'] = file;
        }
      }

      if (this.form.insurance.hasGeneralLiability) {
        const generalLiabilityFilePond = this.$refs['general-liability-pond'].getFiles();
        
        if (generalLiabilityFilePond.length > 0) {
          const file = generalLiabilityFilePond[0].file;
          this.files['General Liability'] = file;
        }
      }

      if (this.form.insurance.hasWorkersComp) {
        const workersCompFilePond = this.$refs['workers-pond'].getFiles();

        if (workersCompFilePond.length > 0) {
          const file = workersCompFilePond[0].file;
          this.files['Workers Comp'] = file;
        }
      }

      this.state.step++;
    },
    async completeStepSix() {
      await Promise.all([
        this.submitForm(),
        this.getContract()
      ]);
      this.state.step++; 
    },
    toggleArrayElement(array, element) {
      if (array.includes(element)) {
        array.splice(array.indexOf(element), 1);
      } else {
        array.push(element);
      }

      return array;
    },
    toggleCategory(category) {
      if (!this.form.categories.includes(category)) {
        this.form.categories.push(category);
      } else {
        this.form.categories = this.form.categories.filter(x => x != category);
      }
    },
    async submitForm() {
      const submit_toast = this.$toast.info("Submitting form. Please wait...");

      const formData = new FormData();
      formData.append("form", JSON.stringify(this.form));

      // Check which files the user has uploaded
      const filesToInclude = ['Logo', 'License', 'General Liability', 'Workers Comp'];
      filesToInclude.forEach(fileName => {
        if (this.files[fileName]) {
          formData.append(fileName, this.files[fileName], this.files[fileName].name);
        }
      });

      await axios.post(`${window.api}/services/onboarding`, formData, { "Content-Type": "multipart/form-data" })
        .then(() => this.$toast.success("Request submitted successfully!"))
        .catch(() => this.$toast.error("An error occurred while trying to process your request"));

      this.$toast.dismiss(submit_toast);
    },
    async getContract() {
      if (this.contractLink == '') {
        this.state.isLoading = true;

        const body = {
          name: this.form.details.yourName, 
          email: this.form.details.email, 
          mobile: this.form.details.mobilePhone, 
          company: this.form.details.name
        };

        await axios.post(`${window.api}/services/contract`, body)
          .then(({ data }) => this.contractLink = data)
          .catch(error => console.log(error));

        this.state.isLoading = false;
      }
    }
  }
}
</script>

<style scoped>
.hero {
  display: block;
  box-sizing: border-box;
  height: 150px;
  background: #56CCF2;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #2F80ED, #56CCF2);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #2F80ED, #56CCF2); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  clip-path: ellipse(114% 100% at 70% 0%);
}
</style>

